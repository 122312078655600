<template>
  <v-btn elevation="0"  @click="callPaymentApi()" class="payment-btn" :loading="loader">
    <img src="http://cityclubeg.com/wp-content/uploads/2021/06/download.png">
  </v-btn>
</template>

<script>
  import common from "../../utils/common";
  export default {
    name: "cib",
    props: {
      value: {
        type: Number,
        default: ()=> {
          return 0
        }
      },
      service_name: {
        type:  Array,
        default: ()=> {
          return []
        }
      },
      saveids: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      courtId: {
        type: Number,
        default: ()=>{
          return null
        }
      },
      subscribeObject: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data(){
      return {
        apiResponse: {},
        order_id: '',
        folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
        currentUrl: `${process.env.VUE_APP_WEBSITE_URL}user/#${this.$route.path}`,
        userID: '',
        resultIndicator: '',
        loader: false
      }
    },
    mounted() {
      this.userID = this.$store.state.user.userId;
      let queryParams =  new URLSearchParams(window.location.search)
      this.resultIndicator = queryParams.get('resultIndicator');
      if(localStorage.order_id) {
        this.order_id = localStorage.order_id;
      }
      if (this.resultIndicator) {
        this.checkPayment()
      }
      this.checkoutScript();
    },
    methods: {
      checkoutScript () {
        let checkoutScript = document.createElement('script')
        checkoutScript.setAttribute('src', 'https://test-gateway.mastercard.com/checkout/version/59/checkout.js')
        checkoutScript.setAttribute('data-complete', this.currentUrl)
        document.head.appendChild(checkoutScript)
      },
      lightBoxPayment(){
        Checkout.configure({
          session: {
            id: this.apiResponse.response.session.id
          },
          merchant: 'CISTESTKH',
          order: {
            amount: this.value,
            currency: 'EGP',
            description: 'Ordered goods',
            id: this.apiResponse.order_id
          },
          interaction: {
            operation: 'PURCHASE', // set this field to 'PURCHASE' for <<checkout>> to perform a Pay Operation.
            merchant: {
              name: 'Sample Merchant',
              address: {
                line1: '200 Sample St',
                line2: '1234 Example Town'
              }
            }
          }
        });

        Checkout.showLightbox();
      },
      callPaymentApi(){
        this.loader = true
        localStorage.mount_payed = this.value;
        localStorage.service_name = this.service_name;

        this.$http_website_without_lang.post('/e-finance-init-session/',{
          amount:this.value,
          currency:'EGP',
          saveIds: this.saveids,
          courtId: this.courtId,
          userID: this.userID,
          user_token: this.$store.state.user.userToken,
          subscribeObject: this.subscribeObject
        }).then(response => {
          this.apiResponse = response.data;
          this.order_id = response.data.order_id;
          localStorage.order_id = response.data.order_id;
          this.lightBoxPayment();
        }).catch(err => {
          console.log('--->'+err);
        })
      },
      checkPayment () {
        this.$http_website_without_lang.post('/e-finance-confirm', {
          order_id: localStorage.order_id,
          result_indicator: this.resultIndicator,
          user_token: this.$store.state.user.userToken
        }).
        then(response => {
            this.$emit('reloadDues');
            this.$store.dispatch('setreceiptState' , true);
          }
        ).catch(err => {
          console.log('error--->'+err);
        })
      }
    },
    computed: {
      cibLogoUri() {
        return  require('../../assets/cib.png');
      }
    }
  }
</script>

<style scoped>

</style>
