<template>
  <div class="">
    <v-card class="pa-5 text-center">
      <v-icon color="green" large>mdi-marker-check</v-icon>
      <h3>{{$t('Your payment successfully completed')}}</h3>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "success",
    data() {
      return {
        success: false,
        orderId: ''
      }
    },
    mounted() {
      let queryParams =  new URLSearchParams(window.location.search)
      this.orderId = queryParams.get('merchant_order_id');
      this.success = queryParams.get('success');
      if (this.orderId && this.success) {
        //this.redirect()
      }
    },
    methods:{
      redirect(){
        this.$http_website_without_lang.get(`paymob-redirect-link?order_id=${this.orderId}`)
        .then(res=>{
          window.location.href=res.data
        })
        .catch(err=>{

        })
      }
    }
  }
</script>

<style scoped>

</style>
