<template>
  <div>
    <schedule-table :items="items" :headers="headers" type="family" :users="users"/>
  </div>
</template>

<script>
  import scheduleTable from "./scheduleTable";
  export default {
    name: "mySchedule",
    components:{
      scheduleTable
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('Day'),
            align: 'start',
            value: 'eDate',
            sortable: false
          },
          { text: this.$t('Name'), value: 'fullName',sortable: false },
          { text: this.$t('Week Days'), value: 'weekDays',sortable: false },
          { text: this.$t('Sport'), value: 'sportActivityName',sortable: false },
          { text: this.$t('From'), value: 'fromTime',sortable: false },
          { text: this.$t('To'), value: 'toTime',sortable: false },
          { text: 'Actions', value: 'actions',sortable: false},
        ],
        items: [],
        users:[this.$t('All')]
      }
    },
    mounted() {
      this.getSchedulesMember()
    },
    methods: {
      getSchedulesMember() {
        this.$http_sys.post('/Schedule/GetScheduleFamily', {
          lang: this.$i18n.locale
        })
          .then(res => {
            this.items = []
            for (let i=0;i<res.data.length;i++){
                this.users.push(res.data[i].fullName)
                this.items.push(res.data[i])
            }
          })
          .catch(error => {

          })
      },
    },

    computed: {
    },

    watch: {

    },
  }
</script>

<style scoped>

</style>
