<template>
  <v-btn elevation="0"  @click="FawryPayment()" class="payment-btn" :loading="loader">
    <img :src="this.folderUrl + fawryLogoUri">
  </v-btn>
</template>

<script>
  export default {
    name: "fawry",
    props: {
      value: {
        type: Number,
        default: ()=> {
          return 0
        }
      },
      saveids: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      courtId: {
        type: Number,
        default: ()=>{
          return null
        }
      },
      isRenewal: {
        type: Boolean,
        default: () => false
      },
      subscribeObject: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data(){
      return {
        fawryConfiguration: {},
        chargeRequest: {},
        item: {},
        order_id: localStorage.order_id,
        userID: '',
        time: new Date(),
        currentUrl: `${process.env.VUE_APP_WEBSITE_URL}user/#${this.$route.path}`,
        folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
        paymentItemDescription: 'Cityclub',
        loader: false
      }
    },
    mounted() {
      this.userID = this.$store.state.user.userId;
      let queryParams =  new URLSearchParams(window.location.search)
      this.chargeResponse = JSON.parse(queryParams.get('chargeResponse'));
      if (this.chargeResponse) {
        this.checkFawryPayment();
      }
      this.checkoutFawryScript();
    },
    methods: {
    checkoutFawryScript() {
      let checkoutFawryScript = document.createElement('script')
      checkoutFawryScript.setAttribute('src', 'https://www.atfawry.com/ECommercePlugin/scripts/V2/FawryPay.js')
      checkoutFawryScript.setAttribute('data-complete', this.currentUrl)
      document.head.appendChild(checkoutFawryScript)
    },
    FawryPayment() {
      this.loader = true
      this.$http_website_without_lang.post('/fawry-payment', {
        amount: this.value,
        currency: 'EGP',
        saveIds: this.saveids,
        courtId: this.courtId,
        isRenewal: this.isRenewal,
        userID: this.userID,
        userToken: this.$store.state.user.userToken,
        subscribeObject: this.subscribeObject
      }).
        then(response => {
          if (response.data.system_api_response) {

            this.apiResponse = response.data;
            this.order_id = response.data.order_id;
            localStorage.order_id = response.data.order_id;
            if(response.data.payment_type)
              this.paymentItemDescription += " " + response.data.payment_type.charAt(0).toUpperCase() + response.data.payment_type.slice(1);
            this.openFawryDialog(response.data.order_id)
          } else {
            this.$store.dispatch('setSnackBarMessage', this.$t('Something went wrong please try again later'))
          }
          this.loader = false
        }).catch(err => {
          console.log('--->' + err);
        });
    },
    openFawryDialog(orderId) {
      // this.loader = true
      // this.chargeRequest.language= 'ar-eg';
      // this.chargeRequest.merchantCode= 'HWBxya5K6DpLur0m+pEiFQ==';
      // this.chargeRequest.merchantRefNumber= orderId
      // this.chargeRequest.customer = {};
      // this.chargeRequest.customer.name = '';
      // this.chargeRequest.customer.mobile = '';
      // this.chargeRequest.customer.email = '';
      // this.chargeRequest.customer.customerProfileId = '';
      // this.chargeRequest.order = {};
      // this.chargeRequest.order.description = 'City Club Order';
      // this.chargeRequest.order.expiry = '';
      // this.chargeRequest.order.orderItems = [];

      // this.item.productSKU = this.time.getTime();
      // this.item.description ='Test Product';
      // this.item.price = this.value;
      // this.item.quantity ='1';
      // this.item.width ='';
      // this.item.height ='';
      // this.item.length ='';
      // this.item.weight ='1';
      // this.chargeRequest.order.orderItems.push(this.item);
      // this.chargeRequest.returnUrl = this.currentUrl;
      // this.chargeRequest.signature = '6621d5674f5e4e4692274f593564e069';

      // console.log("chargeRequest_component");
      // console.log(this.chargeRequest);
      // FawryPay.checkout(this.chargeRequest,'','');

      this.loader = true

      this.fawryConfiguration.locale = 'ar';
      this.fawryConfiguration.mode = DISPLAY_MODE.INSIDE_PAGE;

      this.chargeRequest.merchantCode = '400000010304';
      this.chargeRequest.merchantRefNum = parseInt(orderId);
      this.chargeRequest.customerName = '';
      this.chargeRequest.customerMobile = '';
      this.chargeRequest.customerEmail = '';
      this.chargeRequest.customerProfileId = '';
      this.chargeRequest.chargeItems = [];

      this.item.itemId = this.time.getTime().toString();
      this.item.description = this.paymentItemDescription;
      this.item.price = this.value;
      this.item.quantity = 1;
      this.chargeRequest.chargeItems.push(this.item);
      this.chargeRequest.returnUrl = this.currentUrl;
      this.chargeRequest.authCaptureModePayment = false;
      this.chargeRequest.secKey = "8ea8a821-e291-413f-bc9e-c32c8b680f7e";
      this.chargeRequest.signature = this.signRequest(this.chargeRequest);
      FawryPay.checkout(this.chargeRequest, this.fawryConfiguration);
    },
    signRequest(chargeRequest){
      var signString = chargeRequest['merchantCode']+chargeRequest.merchantRefNum;
      signString += chargeRequest.customerProfileId!=null?chargeRequest.customerProfileId:'';
      signString += chargeRequest.returnUrl!=null?chargeRequest.returnUrl:'';
      var items = chargeRequest.chargeItems.sort(function (x, y) {
        let a = x.itemId.toUpperCase(),
        b = y.itemId.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });

      items.forEach((item) => {
        signString += item.itemId+''+item.quantity+''+item.price.toFixed(2);
      });
      signString += chargeRequest.secKey;
      return sha256(signString);
    },
    checkFawryPayment() {

    }
  },
  computed: {
    fawryLogoUri() {
      return require('../../assets/fawry.png');
    }
  }
  }
</script>

<style scoped>

</style>
