<template>
  <div>
    <v-card class="pa-5">
      <h3 class=" text-uppercase">{{$t('Schedule')}}</h3>
      <v-row>
        <v-col cols="12" md="4" v-for="(item,index) in items" :key="index">
          <v-card class="pa-5 card-hover-primary-bg fill-height align-center d-flex" :to="item.link" height="160">
            <v-row align="center">
              <v-col cols="8">
                <h3 class="text-uppercase mt-2">{{item.title}}</h3>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-icon large>{{item.icon}}</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>

  export default {
    name: "index",
    components: {

    },
    data () {
      return{
        items: [
          {title: this.$t('All Activities'), icon: 'mdi-calendar-clock', link: `/activities-list`},
          {title: this.$t('My Schedule'), icon: 'mdi-account-clock', link: '/my-schedule'},
          {title: this.$t('Family Schedule'), icon: 'mdi-account-group', link: '/family-schedule'},
        ],
      }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
