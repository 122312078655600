<template>
  <v-card class="pa-5 sport-card">
    <img :src="sportData.image" class="feature-image" alt="">
    <h1 class="text--primary mt-3">{{sportData.title}}</h1>
    <div class="mt-3">
      <p> {{sportData.content}}</p>

    </div>
    <div class="mt-3">
      <v-row>
        <v-col cols="5">
          <h3 class="mb-3">{{$t('Levels')}}</h3>
          <v-select
            outlined
            v-model="selectedLvl"
            width="400"
            :items="sportData.levels"
            item-text="name"
            item-value="group_category_code"
            id="family-select"
            attach
            auto
          >
          </v-select>
          <v-btn color="primary" elevation="0" @click="showSchedule">{{$t('Show Schedule')}}</v-btn>
        </v-col>
      </v-row>

    </div>
    <div class="mt-3 ">
      <vue-gallery :images="sportData.gallery" :index="index" @close="index = null"></vue-gallery>
      <v-row class="gallery">
        <v-col
          cols="4"
          md="4"
          sm="6"
          class="image"
          v-for="(image, imageIndex) in sportData.gallery"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <img
            :src="image"
            height="200"
          />
        </v-col>
      </v-row>

    </div>
  </v-card>
</template>

<script>
  import VueGallery from 'vue-gallery';
  export default {
    name: "singleActivity",
    components: {
      VueGallery
    },
    data () {
      return {
        sportData: {},
        selectedLvl: '',
        index: null
      }
    },
    mounted() {
      this.getSportData()
    },
    methods: {
      getSportData () {
        this.$store.dispatch('setLoader', true)
        let code = this.$route.query.code
        this.$http_website.get(`/single-sport/?code=${code}`)
        .then(response=>{
          this.sportData = response.data
          this.$store.dispatch('setLoader', false)
          this.selectedLvl = this.sportData.levels[0].group_category_code
        })
        .catch(error=>{
          this.$store.dispatch('setLoader', false)
        })
      },
      showSchedule() {
        this.$router.push({
          path: 'single-activity-schedule',
          query: {
            group_category_code: this.selectedLvl,
            sport_code: this.$route.query.code
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .sport-card {
    img {

    }

  }
</style>
