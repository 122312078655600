import store from '../store/index'
export default class lsSync {
  static syncData () {
    let userToken = localStorage.getItem('userToken')
    if (userToken === 'null') {
      userToken = ''
    }
    let userName = localStorage.getItem('userName')
    let SecondName = localStorage.getItem('SecondName')
    let membershipId =  localStorage.getItem('membershipId')
    let userId = localStorage.getItem('userId')
    store.dispatch('setUserToken', userToken)
    store.dispatch('setUserName', userName)
    store.dispatch('setUserSecondName', SecondName)
    store.dispatch('setUserID', userId)
    store.dispatch('setMembershipId', membershipId)
  }
}
