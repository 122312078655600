<template>
  <v-btn elevation="0" height="200" @click="callPaymentApi()" class="payment-btn mt-3"  block :loading="loader">
    <img :src="cibLogoUri" height="100">
  </v-btn>
</template>

<script>
export default {
  name: "cib",
  props: {
    value: {
      type: Number,
      default: ()=> {
        return 0
      }
    },
    service_name: {
      type:  Array,
      default: ()=> {
        return []
      }
    },
    saveids: {
      type: Array,
      default: ()=> {
        return []
      }
    },
    courtId: {
      type: Number,
      default: ()=>{
        return null
      }
    },
    subscribeObject: {
      type: Object,
      default: ()=> {
        return {}
      }
    },
    order_id: {
      type: String,
      default: ()=> {
        return ''
      }
    }
  },
  data(){
    return {
      apiResponse: {},
      orderApiResponse: {},
      data_order_id: '',
      folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
      currentUrl: `${process.env.VUE_APP_WEBSITE_URL}user/#${this.$route.path}`,
      userID: '',
      resultIndicator: '',
      loader: false
    }
  },
  mounted() {
    this.userID = this.$store.state.user.userId;
    let queryParams =  new URLSearchParams(window.location.search)
    this.resultIndicator = queryParams.get('resultIndicator');
    if(localStorage.order_id) {
      this.data_order_id = localStorage.order_id;
    }
    if (this.resultIndicator) {
      this.checkPayment()
    }
    this.checkoutScript();
  },
  methods: {
    checkoutScript () {
      let checkoutScript = document.createElement('script')
      checkoutScript.setAttribute('src', 'https://cibpaynow.gateway.mastercard.com/checkout/version/55/checkout.js')
      checkoutScript.setAttribute('data-complete', this.currentUrl)
      document.head.appendChild(checkoutScript)
    },
    lightBoxPayment(order_id){
      Checkout.configure({
        session: {
          id: this.orderApiResponse.reference.substring(4)
        },
        merchant: "CIB700899",
        order: {
          amount: this.value,
          currency: 'EGP',
          description: 'Order',
          id: order_id
        },
        interaction: {
          operation: 'PURCHASE', // set this field to 'PURCHASE' for <<checkout>> to perform a Pay Operation.
          merchant: {
            name: 'Merchant',
            address: {
              line1: '200 Sample St',
              line2: '1234 Example Town'
            }
          }
        }
      });

      Checkout.showLightbox();
    },
    callPaymentApi(){
      this.loader = true
      if (this.order_id) {
        this.$http_website_without_lang.get('/wp_order_details/?order_id='+this.order_id).
        then(response => {
          let responseData = response.data;
          if (responseData.code == 200 && responseData.order_id) {
            this.data_order_id = responseData.order_id;
            this.orderApiResponse = responseData;
            localStorage.order_id = responseData.order_id;
            this.lightBoxPayment(this.order_id)
          } else {
            this.$store.dispatch('setSnackBarMessage', this.$t('Something went wrong please try again later'))
          }
          this.loader = false
        }).catch(err => {
          console.log('error--->'+err);
          this.loader = false
        });
      } else {
        localStorage.mount_payed = this.value;
        localStorage.service_name = this.service_name;
  
        this.$http_website_without_lang.post('/cib-payment/',{
          amount:this.value,
          currency:'EGP',
          saveIds: this.saveids,
          courtId: this.courtId,
          userID: this.userID,
          user_token: this.$store.state.user.userToken,
          subscribeObject: this.subscribeObject
        }).then(response => {
          if (response.data.system_api_response) {
            this.apiResponse = response.data;
            this.data_order_id = response.data.order_id;
            localStorage.order_id = response.data.order_id;
            this.lightBoxPayment();
          } else {
            this.$store.dispatch('setSnackBarMessage', this.$t('Something went wrong please try again later'))
          }
          this.loader = false
        }).catch(err => {
          console.log('error--->'+err);
        })
      }
    },
    checkPayment(){
      this.$http_website_without_lang.post('/check-payment', {
        order_id: localStorage.order_id,
        result_indicator: this.resultIndicator,
        user_token: this.$store.state.user.userToken
      }).
      then(response => {
            this.$emit('reloadDues');
            this.$store.dispatch('setreceiptState' , true);
          }
      ).catch(err => {
        console.log('error--->'+err);
      })
    }
  },
  computed: {
    cibLogoUri() {
      return  require('../../../../assets/cib.png');
    }
  }
}
</script>

<style >
.payment-btn {
  height: 150px !important;
}
</style>
