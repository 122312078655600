<template>
  <div>
    <v-row v-if="userType === 'family'">
      <v-col cols="12" md="6">
        <v-select
          outlined
          v-model="selectedMember"
          @change="$emit('filterFamilyDues', selectedMember)"
          width="400"
          :items="familyMembers"
          attach
          auto
        >
        </v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="dues"
      sort-by="calories"
      class="elevation-1"
      hide-default-footer
      show-select
      item-key="rowNum"
      v-model="selectedItems"
    >

      <template v-slot:body="{ items }">
        <tbody>

        <tr v-for="(item,index) in items" :key="item.rowNum">
          <td>
            <v-checkbox v-model="selectedItems" @change="updateSelected(item)" :disabled="checkDisable(item.rowNum)" :value="item" style="margin:0px;padding:0px"
                        hide-details />
          </td>

          <td>{{ item.safeTrxTypeName }}</td>
          <td v-if="userType !== 'user'">{{ item.personName }}</td>
          <td>{{ common.getDate(item.safeTrxDueDate) }}</td>
          <td>
            {{item.debit}}
          </td>

        </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-right mt-5">
      <v-btn color="primary"  width="150" elevation="0" v-show="totalValue > 0" @click="dialog=true">{{$t('Pay')}} {{totalValue}} {{$t('EGP')}}</v-btn>

    </div>
    <v-dialog v-model="dialog" width="700" eager >
      <v-card class="pa-5 payment-logos">
        <v-layout row>
          <v-flex md3 class="mt-5 d-flex justify-center">
            <cib v-show="totalValue > 0" :value="totalValue" :saveids="safeIDs" :service_name="serviceName" @reloadDues="$emit('reloadDues')"/>
          </v-flex>
          <v-flex md3 class="mt-5 d-flex justify-center">
            <fawry v-show="totalValue > 0" :value="totalValue" :saveids="safeIDs" @reloadDues="$emit('reloadDues')"/>
          </v-flex>
          <v-flex md3 class="mt-5 d-flex justify-center">
            <vodafone v-show="totalValue > 0" :value="totalValue" :saveids="safeIDs" @reloadDues="$emit('reloadDues')"/>
          </v-flex>
        </v-layout>
<!--        <e-finance v-show="totalValue > 0" :value="totalValue" :saveids="safeIDs" @reloadDues="$emit('reloadDues')"/>-->
      </v-card>
    </v-dialog>
      <receipt v-show="$store.state.user.receiptstate" />
      <!-- <receipt /> -->
  </div>
</template>
<script>
  import axios from 'axios'
  import common from "../../../utils/common";
  import cib from "../../payment/cib";
  import receipt from "../../payment/receipt";
  import fawry from "../../payment/fawry";
  import vodafone from "../../payment/vodafone";
  import eFinance from "../../payment/eFinance";
  // import qnb from "../../payment/qnb";
  import _ from 'lodash';
  export default {
    name: "duesTable",
    components: {
      cib,
      fawry,
      receipt,
      vodafone,
      eFinance
    },
    props: {
      userType: {
        type: String,
        default: ()=> {
          return 'user'
        }
      },
      headers: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      dues: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      familyMembers: {
        type: Array,
        default: ()=> {
          return []
        }
      }
    },
    data () {
      return {
        common,
        dialog: false,
        selectedItems: [],
        selectedMember: 'All',
        allFamilyDues: [],
        apiResponse: null,
        paymanetsucess: true,
        serviceName: []
      }
    },
    mounted() {

    },
    methods: {

      checkDisable (rowNum) {
        let itemIndex = this.selectedItems.findIndex((item)=>{
          return item.rowNum > rowNum
        })
        return itemIndex > -1;
      },
      updateSelected (selectedItem){
        let itemIndex = this.dues.findIndex((item)=>{
          return item.rowNum === selectedItem.rowNum
        })

        let selectedItemIndex = this.selectedItems.findIndex((item)=>{
          return item.rowNum === selectedItem.rowNum
        })
        if (itemIndex && selectedItemIndex < itemIndex && selectedItemIndex !== -1) {
          this.selectedItems= [];
          for (let i = 0 ;i<=itemIndex;i++) {
            this.selectedItems.push(this.dues[i]);
          }
        }
      }
    },

    computed: {
      totalValue () {
        let sum = 0
        let service_name = []
        for (let i=0; i<this.selectedItems.length; i++) {
          service_name[i] = this.selectedItems[i].safeTrxTypeName
          sum += this.selectedItems[i].debit
        }

        this.serviceName = service_name;
        return sum
      },
      safeIDs() {
         let ids = [];
        for (let i=0; i<this.selectedItems.length; i++) {
          ids.push(this.selectedItems[i].safeTrxId)
        }
        return ids
      }
    },

    watch: {
      dues(){
        this.selectedItems = []
      }
    },
  }
</script>

<style scoped>

</style>
