<template>
  <div>
    <v-card class="pa-5 mb-3">
      <h3 class=" text-uppercase">{{$t('All Activities')}}</h3>
      <v-row>
        <v-col cols="6">
          <v-select
            outlined
            v-model="selectedSport"
            width="400"
            :items="sports"
            item-text="sportActivityName"
            item-value="sportActivityID"
            id="sport-select"
            :placeholder="$t('Select Sport')"
            @change="getLevels"
            attach
            auto
          >
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            outlined
            v-model="selectedLevel"
            width="400"
            :items="levels"
            item-text="groupTypeName"
            item-value="groupCategoryID"
            id="family-select"
            :placeholder="$t('Select Level')"
            :disabled="!selectedSport"
            @change="getSchedule"
            attach
            auto
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-5 mb-3 sport-card" v-for="(data,index) in scheduleData" :key="propertyName">

      <h4 class="text-uppercase mb-5">{{data.sportGroupName}}</h4>
      <v-row>
        <v-col cols="12" md="6">
          <p>{{data.weekDays}}</p>
        </v-col>
        <v-col cols="12" md="6" class="text-md-right">
          <p class="text--primary">{{common.convert24to12(data.fromTime.hours , data.fromTime.minutes)}} - {{common.convert24to12(data.toTime.hours ,data.toTime.minutes)}}</p>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import common from "../../../../utils/common";
  import _ from "lodash";

  export default {
    name: "activitesList",
    data () {
      return {
        sports: [],
        levels: [],
        selectedSport: '',
        selectedLevel: '',
        scheduleData: [],
        scheduleDataGrouped: {},
        common,
      }
    },
    mounted() {
      this.getAllSports()
    },
    methods: {
      getAllSports() {
        this.$store.dispatch('setLoader', true)
        this.$http_sys.post('/Court/GetAllSportsActivitySchedules',{
          lang: this.$i18n.locale
        })
        .then(response=>{
          this.sports = response.data
          this.$store.dispatch('setLoader', false)
        })
        .catch(error=>{
          this.$store.dispatch('setLoader', false)
        })
      },
      getLevels(){
        this.selectedLevel = ''
        this.$store.dispatch('setLoader', true)
        this.$http_sys.post('/Court/GetAllGroupCategorySchedules',{
          lang: this.$i18n.locale,
          sportActivityID: this.selectedSport,
        })
          .then(response=>{
            this.levels = response.data
            this.$store.dispatch('setLoader', false)
          })
          .catch(error=>{
            this.$store.dispatch('setLoader', false)
          })
      },
      getSchedule (){
        this.$store.dispatch('loader', true)
        this.scheduleData = []
        this.$http_sys.post('Schedule/GetAllSchedules', {
          sportActivityID: this.selectedSport,
          groupCategoryID: this.selectedLevel,
          lang: this.$i18n.locale
        })
          .then(response=>{
            this.scheduleData = response.data
            this.scheduleDataGrouped =   _.groupBy(this.scheduleData,"dayName")
            this.$store.dispatch('loader', false)
          })
          .catch(error=>{
            this.$store.dispatch('loader', false)
          })
      },
      goRoute(code) {
        this.$router.push({
          path: '/single-activity',
          query: {
            code
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
