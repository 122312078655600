<template>
  <div class="receipt" v-show="openReceipt">
    <di class="receipt-content">
      <div id="receiptPayment">
        <img :src="this.folderUrl + cityLogoUri" class="receipt-logo"
          style="
          width: 200px;
      display: block;
      margin: 20px auto 0;">
        <h3 style="
        font-size: 30px;
        padding: 30px 30px 15px;
        text-align: center;
        ">{{$t('Receipt')}}</h3>
        <p style="text-align: center; padding: 15px; border-bottom: 1px solid rgba(0,0,0,0.4);">{{ order_id }}</p>
        <div class="data"  style="text-align: right; padding: 30px">
          <p style="font-size: 20px; direction:rtl"><strong>{{$t('A payment receipt from Mr. / Mrs.')}}</strong> <span> : {{userName + '  ' +userSecondName }}</span></p>
          <p style="font-size: 20px; direction:rtl"><strong>{{$t('Membership No')}} </strong> <span> : {{membershipId}}</span></p>
          <p style="font-size: 20px; direction:rtl"><strong>{{$t('Service Name')}} </strong> <span> : {{serviceName}}</span></p>
          <p style="font-size: 20px; direction:rtl"><strong>{{$t('The total amount paid')}} </strong> <span> : {{mountpayed}}</span></p>
          <p style="font-size: 20px; direction:rtl"><strong>{{$t('Receipt date')}} </strong> <span> : {{currentDate}}</span></p>
        </div>
      </div>
      <div class="action-btn">
        <button @click="closeReceipt()" class="close">{{$t('Cancel')}}</button>
        <button @click="print()" class="print">{{$t('Print')}}</button>
      </div>
    </di>
  </div>
</template>

<script>
  import common from "../../utils/common";
  export default {
    name: "receipt",
    data(){
      return {
        userName: '',
        userSecondName: '',
        membershipId: '',
        mountpayed: 0,
        openReceipt: true,
        serviceName: [],
        service_name_str: '',
        order_id: '',
        folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
        currentDate: new Date().toJSON().slice(0,19).replace(/-/g,'/'),
      }
    },
    mounted() {
      console.log(this.$store.state.user)
      this.userName = this.$store.state.user.userName;
      this.userSecondName = this.$store.state.user.SecondName;
      this.mountpayed = localStorage.mount_payed;
      this.serviceName = localStorage.service_name;
      this.order_id = localStorage.order_id;
      this.membershipId = this.$store.state.user.membershipId;

      for (let i=0; i<this.serviceName.length; i++) {
        if(this.service_name_str != '') {
          this.service_name_str += ' - '
        }
        this.service_name_str += this.serviceName[i]
      }
    },
    methods: {
      print() {
        var prtContent = document.getElementById("receiptPayment");
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
      },
      closeReceipt() {
        this.openReceipt = false;
        this.$store.dispatch('setreceiptState' , false);
        localStorage.mount_payed = 0;
        localStorage.order_id = null;
        localStorage.service_name = '';
      }
    },
    computed: {
       cityLogoUri() {
        return  require('../../assets/city-logo.png');
      }
    }
  }
</script>
