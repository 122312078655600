var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"calories","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('change-dialog',{attrs:{"edited-item":_vm.editedItem,"edited-index":_vm.editedIndex}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('Delete Schedule')))]),_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.$t('Are sure you want to delete this schedule?')))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t('Ok')))])],1)],1)],1)]},proxy:true},{key:"item.eDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.eDate).format('MM/DD/YYYY'))+" ")]}},{key:"item.fromTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.common.convert24to12(item.fromTime.hours , item.fromTime.minutes))+" ")]}},{key:"item.toTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.common.convert24to12(item.toTime.hours , item.toTime.minutes))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" Loading ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }