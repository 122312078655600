<template>
  <v-btn elevation="0" block @click="FawryPayment()" class="payment-btn mt-3" :loading="loader">
    <img :src="fawryLogoUri" height="100">
  </v-btn>
</template>

<script>
import {sha256} from "js-sha256";
export default {
  name: "fawry",
  props: {
    value: {
      type: Number,
      default: ()=> {
        return 0
      }
    },
    saveids: {
      type: Array,
      default: ()=> {
        return []
      }
    },
    courtId: {
      type: Number,
      default: ()=>{
        return null
      }
    },
    isRenewal: {
      type: Boolean,
      default: () => false
    },
    subscribeObject: {
      type: Object,
      default: ()=> {
        return {}
      }
    },
    order_id: {
      type: String,
      default: ()=> {
        return ''
      }
    },
    dues_type: {
      type: String,
      default: ()=> {
        return 'family'
      }
    }
  },
  data(){
    return {
      chargeRequest: {merchantCode: "400000010304"},
      secKey: "8ea8a821-e291-413f-bc9e-c32c8b680f7e",
      fawryConfiguration:{},
      item: {},
      userID: '',
      time: new Date(),
      currentUrl: `${process.env.VUE_APP_WEBSITE_URL}/#${this.$route.path}`,
      folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
      paymentItemDescription: 'Cityclub',
      loader: false
    }
  },
  mounted() {
    this.userID = this.$store.state.user.userId;
    let queryParams =  new URLSearchParams(window.location.search)
    this.chargeResponse = JSON.parse(queryParams.get('chargeResponse'));
    if (this.chargeResponse) {
      this.checkFawryPayment();
    }
    this.checkoutFawryScript();
  },
  methods: {
    checkoutFawryScript () {
      let checkoutFawryScript = document.createElement('script')
      checkoutFawryScript.setAttribute('src', 'https://www.atfawry.com/atfawry/plugin/assets/payments/js/fawrypay-payments.js')
      checkoutFawryScript.setAttribute('type', 'text/javascript')
      document.head.appendChild(checkoutFawryScript)
      
      // let sha256Script = document.createElement('script')
      // sha256Script.setAttribute('src', 'https://cdn.jsdelivr.net/gh/emn178/js-sha256/build/sha256.min.js')
      // document.head.appendChild(sha256Script)
    },
    FawryPayment(){
      this.loader = true
      if (this.order_id) {
        this.$http_website_without_lang.get('/wp_order_details/?order_id='+this.order_id).
        then(response => {
          let responseData = response.data;
          if (responseData.code == 200 && responseData.order_id) {
            if('onlineApplication' == responseData.type || ('dues' == responseData.type && 'user' == this.dues_type)){
              this.chargeRequest.merchantCode = "HWBxya5K6DpLur0m+pEiFQ==";
              this.secKey = "6621d5674f5e4e4692274f593564e069";
            }

            if(responseData.type)
              this.paymentItemDescription += " " + responseData.type.charAt(0).toUpperCase() + responseData.type.slice(1);

            this.openFawryDialog(responseData.order_id)
          } else {
            this.$store.dispatch('setSnackBarMessage', this.$t('Something went wrong please try again later'))
          }
          this.loader = false
        }).catch(err => {
          console.log('--->'+err);
          this.loader = false
        });
      }
      else{
        this.$http_website_without_lang.post('/fawry-payment' , {
          amount:this.value,
          currency:'EGP',
          saveIds: this.saveids,
          courtId: this.courtId,
          isRenewal: this.isRenewal,
          userID: this.userID,
          userToken: this.$store.state.user.userToken,
          subscribeObject: this.subscribeObject
        }).
        then(response => {
          if (response.data.system_api_response.message) {
  
            this.apiResponse = response.data;
            this.order_id = response.data.order_id;
            localStorage.order_id = response.data.order_id;
            if(this.subscribeObject || (this.saveids && 'user' == this.dues_type) ){
              this.chargeRequest.merchantCode = "HWBxya5K6DpLur0m+pEiFQ==";
              this.secKey = "6621d5674f5e4e4692274f593564e069";
            }
            this.openFawryDialog(response.data.order_id)
          } else {
            this.$store.dispatch('setSnackBarMessage', this.$t('Something went wrong please try again later'))
          }
          this.loader = false
        }).catch(err => {
          console.log('--->'+err);
        });
      }
    },
    openFawryDialog(orderId) {
      this.loader = true

      this.fawryConfiguration.locale = 'ar';
      this.fawryConfiguration.mode = DISPLAY_MODE.INSIDE_PAGE;
      
      this.chargeRequest.merchantRefNum= orderId;
      this.chargeRequest.customerName = '';
      this.chargeRequest.customerMobile = '';
      this.chargeRequest.customerEmail = '';
      this.chargeRequest.customerProfileId = '';
      this.chargeRequest.chargeItems = [];

      this.item.itemId = this.time.getTime().toString();
      this.item.description = this.paymentItemDescription;
      this.item.price = parseFloat(this.value);
      this.item.quantity =1;
      this.chargeRequest.chargeItems.push(this.item);
      this.chargeRequest.returnUrl = this.currentUrl;
      this.chargeRequest.authCaptureModePayment= false;
      this.chargeRequest.signature = this.signRequest(this.chargeRequest, this.secKey);

      FawryPay.checkout(this.chargeRequest,this.fawryConfiguration);

    },
    signRequest(chargeRequest, secKey){
        var signString = chargeRequest.merchantCode+chargeRequest.merchantRefNum;
            signString += chargeRequest.customerProfileId!=null?chargeRequest.customerProfileId:'';
            signString += chargeRequest.returnUrl!=null?chargeRequest.returnUrl:'';
        var items = chargeRequest.chargeItems.sort(function (x, y) {
          let a = x.itemId.toUpperCase(),
              b = y.itemId.toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;});
	
        items.forEach((item) => {
              signString += item.itemId+''+item.quantity+''+item.price.toFixed(2);
        });
        signString += secKey;
        
        return sha256(signString);
		  },
  },
  computed: {
    fawryLogoUri() {
      return  require('../../../../assets/fawry.png');
    }
  }
}
</script>

<style scoped>

</style>
