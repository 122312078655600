export const user = {
  state: {
    userToken: '',
    userName: '',
    SecondName: '',
    userEmail: '',
    userId: '',
    receiptstate: false,
    mountpayed: 0,
    serviceName: '',
    OrderId: '',
    membershipId: ''
  },
  mutations: {
    'SET_USER_TOKEN' (state , token){
      state.userToken = token
      localStorage.setItem('userToken', token)
    },
    'SET_USER_NAME' (state, userName) {
      state.userName = userName
      localStorage.setItem('userName', userName)
    },
    'SET_USER_SECOND_NAME' (state,SecondName) {
      state.SecondName = SecondName
      localStorage.setItem('SecondName', SecondName)
    },
    'SET_USER_EMAIL' (state,userEmail) {
      state.userEmail = userEmail
      localStorage.setItem('userEmail', userEmail)
    },
    'SET_USER_ID' (state,userId) {
      state.userId = userId
      localStorage.setItem('userId', userId)
    },
    'SET_MEMBERSHIP_ID' (state,membershipId) {
      state.membershipId = membershipId
      localStorage.setItem('membershipId', membershipId)
    },
    'SET_RECEIPT_STATE' (state,receiptstate) {
      state.receiptstate = receiptstate
    },
  },
  actions: {
    setUserToken({commit},token){
      commit('SET_USER_TOKEN',token)
    },
    setUserName({commit},userName){
      commit('SET_USER_NAME',userName)
    },
    setUserEmail({commit},userEmail){
      commit('SET_USER_EMAIL',userEmail)
    },
    setUserID({commit},userId) {
      commit('SET_USER_ID', userId)
    },
    setUserSecondName({commit},SecondName) {
      commit('SET_USER_SECOND_NAME', SecondName)
    },
    setMembershipId({commit},membershipId) {
      commit('SET_MEMBERSHIP_ID', membershipId)
    },
    setreceiptState({commit},receiptstate) {
      commit('SET_RECEIPT_STATE', receiptstate)
    },
  }
}
