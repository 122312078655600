<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="pa-5">
          <div>
            <h2 CLASS="text--primary text-uppercase">{{$t('Login')}}</h2>
            <p class="caption">{{$t('Login to your account')}}</p>
          </div>
          <v-card-text class="pa-0">
            <v-form ref="loginForm">
              <v-text-field
                outlined
                v-model="userObj.userName"
                :rules="[v=> !!v || $t('User name is required')]"
                :placeholder="$t('User name *')"
              >
              </v-text-field>
              <v-text-field
                outlined
                v-model="userObj.password"
                :rules="[v=> !!v || $t('Password is required')]"
                :placeholder="$t('Password *')"
                type="password">
              </v-text-field>
            </v-form>
            <v-row>
              <v-col><p>{{$t('Do not have account')}} <a @click="$router.push('/register')">{{$t('Register now')}}</a></p></v-col>
              <v-col class="text-right"><a @click="$router.push('/forget-password')">{{$t('Forget Password')}}</a></v-col>
            </v-row>

          </v-card-text>
          <v-card-actions class="d-block text-center">
            <v-btn :loading="loader"  width="150" @click="login" elevation="0" color="primary">{{$t('Login')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        dobMenu: false,
        userObj: {
          userName: '',
          password: '',
        },
        loader: false
      }
    },
    methods: {
      login () {

        if (this.$refs.loginForm.validate()) {
          this.loader = true
          this.userObj.lang = this.$i18n.locale
          this.$http_sys_no_auth.post('Authentication/Login',this.userObj)
            .then(response=>{
              this.$store.dispatch('setUserToken', response.data.auth_token)
              this.$store.dispatch('setUserName', response.data.name)
              this.$store.dispatch('setUserSecondName', response.data.secondName)
              this.$store.dispatch('setMembershipId', response.data.membershipId)
              this.$store.dispatch('setUserID', response.data.id)
              console.log(response.data)
              this.loader = false
              window.location.href = '/user/'

            })
            .catch(error=>{
              this.loader = false
              this.$store.dispatch('setSnackBarMessage',error.response.data.errorMessage)
            })
        }
      },
      saveDob (date) {
        this.$refs.dobMenu.save(date)
      },
    },
    watch: {
      dobMenu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    }
  }
</script>
<style>

</style>
