<template>
  <div>
    <v-card class="pa-5">
      <h3 class=" text-uppercase">{{$t('Dues')}}</h3>
      <div>
        <v-tabs v-model="tab">
          <v-tab>
            {{$t('My Dues')}}
          </v-tab>
          <v-tab>
            {{$t('Family Dues')}}
          </v-tab>
          <v-tab-item>
            <dues-table user-type="user" :headers="headersUser" :dues="userDues" @reloadDues="reloadDues"/>
          </v-tab-item>
          <v-tab-item>
            <dues-table
              user-type="family"
              @reloadDues="reloadDues"
              :headers="headersFamily"
              :dues="selectedFamilyDues"
              :family-members="familyMembers"
              @filterFamilyDues="filterFamilyDues"
              v-if="familyDues.length"
            />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-card>
  </div>
</template>
<script>
  import duesTable from "./duesTable";
  import common from "../../../utils/common";
  export default {
    name: "index",
    components: {
      duesTable
    },
    data () {
      return {
        tab: 0,
        userDues: [],
        familyDues: [],
        selectedFamilyDues: [],
        familyMembers: ['All'],
        headersUser: [
          {
            text: this.$t('Description'),
            align: 'center',
            value: 'safeTrxTypeName',
            sortable: false
          },
          { text: this.$t('Date'), value: 'safeTrxDueDate',sortable: false , align: 'center', },
          { text: this.$t('Value'), value: 'safeTrxDueDate',sortable: false , align: 'center',},
        ],
        headersFamily: [
          {
            text: this.$t('Description'),
            align: 'center',
            value: 'safeTrxTypeName',
            sortable: false
          },
          { text: this.$t('User'), value: 'personName',sortable: false },
          { text: this.$t('Date'), value: 'safeTrxDueDate',sortable: false },
          { text: this.$t('Value'), value: 'safeTrxDueDate',sortable: false },
        ],
        checkPaymentApiRes : null,
        resultIndicator: '',
        chargeResponse: {},
        userID: '',
      }
    },
    mounted() {
      let queryParams =  new URLSearchParams(window.location.search)
      this.resultIndicator = queryParams.get('resultIndicator');
      this.chargeResponse = JSON.parse(queryParams.get('chargeResponse'));
    
      if (!this.resultIndicator ) {
        this.getUserDues()
        this.getFamilyDues()
      }
      this.userID = this.$store.state.user.userId;
      // this.checkPayment()
    },
    methods: {
      getUserDues() {
        this.$store.dispatch('setLoader',true)
        this.$http_sys.post('Dues/GetAllDues', {
          lang: this.$i18n.locale
        })
          .then(response=>{
            this.userDues = response.data
            this.$store.dispatch('setLoader',false)
          })
          .catch(error=>{
            if (error.response.status === 401) {
              //common.logOut()
            }
            this.$store.dispatch('setLoader',false)
          })
      },
      getFamilyDues() {
        this.$store.dispatch('setLoader',true)
        this.$http_sys.post('Dues/GetAllFamilyDues', {
          lang: this.$i18n.locale
        })
          .then(response=>{
            this.familyDues = response.data
            this.selectedFamilyDues = response.data
            for (let i=0; i<this.familyDues.length;i++) {
              if (this.familyMembers.indexOf(this.familyDues[i].personName) === -1) {
                this.familyMembers.push(this.familyDues[i].personName)
              }
            }
            this.$store.dispatch('setLoader',false)
          })
          .catch(error=>{
            if (error.response.status === 401) {
              //common.logOut()
            }
            this.$store.dispatch('setLoader',false)
          })
      },
      filterFamilyDues(user) {
        this.selectedFamilyDues = []
        if (user === 'All') {
          this.selectedFamilyDues = _.cloneDeep(this.familyDues)
        } else {
          for (let i=0; i<this.familyDues.length;i++) {
            if (this.familyDues[i].personName === user) {
              this.selectedFamilyDues.push(this.familyDues[i])
            }
          }
        }
      },
      reloadDues() {
        this.getUserDues()
        this.getFamilyDues()
      }
    }

  }
</script>

<style scoped>

</style>
