import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import Home from "./components/views/Home";
import Register from './components/views/user/register'
import Schedule from './components/views/Schedule'
import Dues from './components/views/Dues'
import BookEvent from './components/views/BookEvent'
import BookCourt from './components/views/BookCourt'
import manageAccount from "./components/views/user/manageAccount";
import login from "./components/views/user/login";
import resetPassword from "./components/views/user/resetPassword";
import validateResetPassword from "./components/views/user/validateResetPassword";
import UPG from "./components/payment/UPG";
import activitesList from "./components/views/Schedule/allActivites/activitesList";
import singleActivity from "./components/views/Schedule/allActivites/singleActivity";
import singleActivitySchedule from "./components/views/Schedule/allActivites/singleActivitySchedule";
import myScheduleTable from "./components/views/Schedule/scheduleTables/myScheduleTable";
import familySchduleTable from "./components/views/Schedule/scheduleTables/familySchduleTable";
import comingSoon from "./components/views/ComingSoon/comingSoon";
import favoriteSportingActivity from "./components/views/favoriteSportingActivity"
import success from "./components/payment/success";
import Subscribe from './components/views/Subscribe'
import paymentOptions from "@/components/views/webview/paymentOptions";

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: paymentOptions,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: Schedule,
      meta: { layout: 'default'}
    },
    {
      path: '/dues',
      name: 'Dues',
      component: Dues,
      meta: { layout: 'default'}
    },
    {
      path: '/book-event',
      name: 'Book Event',
      component: BookEvent,
      meta: { layout: 'default'}
    },
    {
      path: '/book-court',
      name: 'Book Court',
      component: BookCourt,
      meta: { layout: 'default'}
    },
    {
      path: '/manage-account',
      name: 'Manage Account',
      component: manageAccount,
      meta: { layout: 'default'}
    },
    {
      path: '/login',
      name: 'Login',
      component: login,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/payment',
      name: 'Payment',
      component: UPG,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/forget-password',
      name: 'Reset Password',
      component: resetPassword,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/validate-password',
      name: 'Validate Password',
      component: validateResetPassword,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/الانشطة-الرياضية/',
      beforeEnter() {location.href = `${process.env.VUE_APP_WEBSITE_URL}/الانشطة-الرياضية`}
    },
    {
      path: '/activities-list',
      name: 'Activities List',
      component: activitesList,
      meta: { layout: 'default'}
    },
    {
      path: '/single-activity',
      name: 'Single Activity',
      component: singleActivity,
      meta: { layout: 'default'}
    },
    {
      path: '/single-activity-schedule',
      name: 'Single Activity schedule',
      component: singleActivitySchedule,
      meta: { layout: 'default'}
    },
    {
      path: '/my-schedule',
      name: 'My Schedule',
      component: myScheduleTable,
      meta: { layout: 'default'}
    },
    {
      path: '/family-schedule',
      name: 'Family Schedule',
      component: familySchduleTable,
      meta: { layout: 'default'}
    },
    {
      path: '/coming-soon',
      name: 'Coming Soon',
      component: comingSoon,
      meta: { layout: 'default'}
    },
    {
      path: '/favorite-sporting-activity',
      name: 'favorite-sporting-activity',
      component: favoriteSportingActivity,
      meta: { layout: 'default'}
    },
    {
      path: '/success',
      name: 'success',
      component: success,
      meta: { layout: 'no-sidebar'}
    },
    {
      path: '/subscribe',
      name: 'Subscribe',
      component: Subscribe,
      meta: { layout: 'no-sidebar'}
    },
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const allowedPaths = ['/login', '/subscribe' ,  '/forget-password' , '/validate-password' , '/register' , '/success','/']
  if (!store.state.user.userToken  && !allowedPaths.includes(to.path)) {
    return next('/login');
  }

  if (store.state.user.userToken && to.path === '/login') {
    return next('/');
  }
  
  next();
})

export default router
