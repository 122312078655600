<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-select
          v-model="editedItem.date"
          outlined
          :items="['Sunday', 'Monday' , 'Tuesday' , 'Wednesday' , 'Thursday' , 'Friday', 'Saturday']"
          placeholder="day"

        ></v-select>
        <v-select
          v-model="editedItem.time"
          outlined
          :items="['2PM - 5PM' , '5PM - 8PM']"
          placeholder="time"

        ></v-select>
        <v-text-field
          v-model="editedItem.userName"
          outlined
          v-if="editedIndex === -1"
          placeholder="User Name"
        ></v-text-field>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: "changeDialog",
    props: {
      editedItem: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      editedIndex: {
        type: Number,
        default: ()=>{
          return -1
        }
      },
      editMode: {
        type: String,
        default: ()=>{
          return 'edit'
        }
      }
    },
    data(){
      return {

      }
    },
    mounted() {
      if (this.editedItem.groupMemberID) {
        if (this.editMode === 'edit') {
          this.getChangeAvailableGroup()
        } else {
          this.getAddAvailableGroup()
        }
      }
    },
    methods: {
      deleteItemConfirm () {
        this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem)
        } else {
          this.items.push(this.editedItem)
        }
        this.close()
      },
      getChangeAvailableGroup () {
        this.$http_sys.post('/Schedule/GetAddAvailableGroup',{
          groupMemberID: this.editedItem.groupMemberID,
          lang: this.$i18n.locale
        })
          .then(response=>{

          })
          .catch(error=>{

          })
      },
      getAddAvailableGroup() {
        this.$http_sys.post('/Schedule/GetChangeAvailableGroup',{
          groupMemberID: this.editedItem.groupMemberID,
          lang: this.$i18n.locale
        })
          .then(response=>{

          })
          .catch(error=>{

          })
      }
    },
    computed: {
      groupMemberId(){
        return this.editedItem.groupMemberID
      }
    },
    watch: {
      groupMemberId(val) {
        if (val) {
          if (this.editMode === 'edit') {
            this.getChangeAvailableGroup()
          } else {
            this.getAddAvailableGroup()
          }
        }

      }
    }
  }
</script>

<style scoped>

</style>
