<template>
  <div>
    <v-card class="pa-5 book-court">
      <h3 class=" text-uppercase">{{$t('Book Your Court')}}</h3>


      <v-form ref="filterForm">
        <v-row class="ml-0 mr-0 mt-5 pa-0">
            <v-col cols="12" md="4" class="pt-0 pb-0 relative menuDate">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                attach=".menuDate"
                
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterObject.date"
                    :placeholder="$t('Date')"
                    readonly
                    :rules="[v=> !!v || $t('Date is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar-outline"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterObject.date"
                  @input="menuDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" class="pt-0 pb-0 relative menuTimeFrom">
              <v-menu
                v-model="menuTimeFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                attach=".menuTimeFrom"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterObject.fromTime"
                    :placeholder="$t('Time From')"
                    readonly
                    :rules="[v=> !!v || $t('Time is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-clock-outline"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="filterObject.fromTime"
                  @input="menuTimeFrom = false"
                  :allowed-minutes="allowedMinutes"
                  :min="moment(filterObject.sportActivity.startDayTime).format('HH:mm')"
                  :max="moment(filterObject.sportActivity.endDayTime).format('HH:mm')"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col class="pt-0 pb-0 relative menuTimeTo" cols="12" md="4">
              <v-menu
                v-model="menuTimeTo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                attach=".menuTimeTo"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterObject.toTime"
                    :placeholder="$t('Time to')"
                    readonly
                    :rules="[v=> !!v || $t('Time is required')]"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-clock-outline"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="filterObject.toTime"
                  @input="menuTimeTo = false"
                  :min="moment(filterObject.fromTime, 'HH:mm').add(filterObject.sportActivity.minimumDurationInMinutes, 'minutes').format('HH:mm')"
                  :max="moment(filterObject.sportActivity.endDayTime).format('HH:mm')"
                  :allowed-minutes="allowedMinutes"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col class="pt-0 pb-0"  cols="12" md="6" >
              <v-select
                outlined
                v-model="filterObject.sportActivity"
                width="400"
                @change="GetAllCoaches"
                :items="sports"
                return-object
                item-text="sportActivityName"
                :placeholder="$t('Choose Sport')"
                item-value="sportActivityID"
                id="family-select"
                attach
                auto
              >
              </v-select>
            </v-col>

            <v-col class="pt-0 pb-0"  cols="12" md="6">
              <v-btn color="primary" block height="40" @click="findCourt" elevation="0">{{$t('Find Court')}}</v-btn>
            </v-col>
        </v-row>
      </v-form>

        <div class="courts-result">
                <v-card
                        v-for="(court,i) in courts"
                        :key="i"
                        class="pa-5 mb-4"
                >
                      <v-row>
                        <v-col cols="4">
                          <p class="mb-0">{{$t('Court Name')}}</p>
                          <h4>{{court.playgroundName}}</h4>
                        </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Sport')}}</p>
                               <h4>{{court.sportActivityName}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Date')}}</p>
                              <h4>{{filterObject.date}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Start Time')}}</p>
                              <h4>{{filterObject.fromTime}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('End Time')}}</p>
                              <h4>{{filterObject.toTime}}</h4>
                          </v-col>
                          <v-col cols="4">
                              <p class="mb-0">{{$t('Duration')}}</p>
                              <h4>{{hours  }} {{$t('Hours')}}</h4>
                          </v-col>
                      </v-row>
                  <div class="btn-height-fix">
                    <v-btn color="primary"  width="150" elevation="0"  @click="bookCourt(court)">{{$t('Book Now')}} {{court.totalFees}} {{$t('EGP')}}</v-btn>
                  </div>
                </v-card>
        </div>

    </v-card>
    <v-dialog v-model="dialog" width="700" eager >
      <v-card class="pa-5 payment-logos">
          <v-layout row>
              <v-flex md3 class="mt-5 d-flex justify-center">
                  <cib  :value="selectedCourt.totalFees" :court-id="selectedCourt.safeTrxID" :service_name="[selectedCourt.playgroundName]" @reloadDues="$emit('reloadDues')"/>
              </v-flex>
              <v-flex md3 class="mt-5 d-flex justify-center">
                  <fawry  :value="selectedCourt.totalFees" :court-id="selectedCourt.safeTrxID" @reloadDues="$emit('reloadDues')"/>
              </v-flex>
              <v-flex md3 class="mt-5 d-flex justify-center">
                  <vodafone  :value="selectedCourt.totalFees" :court-id="selectedCourt.safeTrxID" @reloadDues="$emit('reloadDues')"/>
              </v-flex>
          </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import common from "../../../utils/common";
  import moment from 'moment'
  import cib from "../../payment/cib";
  import fawry from "../../payment/fawry";
  import vodafone from "../../payment/vodafone";
  import _ from 'lodash'
  export default {
    components: {
      cib,
      fawry,
      vodafone
    },
    data() {
      return {
        menuDate: false,
        menuTimeFrom: false,
        menuTimeTo: false,
        sports: [],
        hours: 0,
        moment,
        filterObject: {
          date: '',
          fromTime: '',
          toTime:'',
          sportActivity: ''
        },
        courts: [],
        reservationData: {},
        selectedSport:{},
        coaches: [],
        selectedCourt: {},
        dialog: false
      }
    },
    mounted() {
      //this.getAllSports()
      this.getReservationData()
    },
    methods: {
      bookCourt(court) {
        this.selectedCourt = _.cloneDeep(court)
        this.dialog= true
      },
      getAllSports() {
        this.$store.dispatch('setLoader', true)
        this.$http_website.get('sports')
          .then(response=>{
            this.sports = response.data
            this.filterObject.sportActivity = this.sports[0].code
            this.$store.dispatch('setLoader', false)
          })
          .catch(error=>{
            this.$store.dispatch('setLoader', false)
          })
      },
      GetAllCoaches() {
        this.filterObject.date = ''
        this.filterObject.fromTime = ''
        this.filterObject.toTime = ''
        this.$store.dispatch('setLoader', true)
        this.$http_sys.post('/Coach/GetAllCoaches',{
          lang: this.$i18n.locale,
          sportActivityID: this.filterObject.sportActivity.sportActivityID
        })
          .then(res=>{
            this.$store.dispatch('setLoader', false)
            this.coaches = res.data
          })
          .catch(err=>{
            this.$store.dispatch('setLoader', false)
          })
      },
      getReservationData() {
        this.$store.dispatch('setLoader', true)
        this.$http_sys.post('/Court/GetSportActivityReservationData',{
          lang: this.$i18n.locale,
          branch: 0
        })
        .then(res=>{
          this.$store.dispatch('setLoader', false)
          this.sports = res.data
          this.filterObject.sportActivity = this.sports[0]
          this.GetAllCoaches()
        })
        .catch(err=>{
          this.$store.dispatch('setLoader', false)
        })
      },
      allowedMinutes(time) {
        return time % this.filterObject.sportActivity.minimumDurationInMinutes === 0
      },
      findCourt() {
        if (this.$refs.filterForm.validate()) {
          this.$store.dispatch('setLoader',true)
            let startTime = moment(this.filterObject.fromTime, "HH:mm");
            let endTime = moment(this.filterObject.toTime, "HH:mm");

// calculate total duration
            this.hours = moment.duration(endTime.diff(startTime)).asHours()

          this.$http_sys.post('Court/GetCourtAvailable',{
            sportActivityID: this.filterObject.sportActivity.sportActivityID,
            startTime: this.filterObject.fromTime,
            endTime: this.filterObject.toTime,
            reservationDate: this.filterObject.date,
            lang: this.$i18n.locale,
            playerTypeId: 1,
            reservationType: 0
          })
          .then(response=>{
            this.courts = response.data
            this.$store.dispatch('setLoader', false)
          })
          .catch(error=>{
            this.$store.dispatch('setLoader', false)
            if (error.response.status === 401) {
              common.logOut()
            }
          })
        }
      }
    },
    watch: {
      'this.filterObject.sportActivity.sportActivityID' : ()=>{
        this.GetAllCoaches()
      }
    }
  }
</script>

<style scoped>
.book-court {
  min-height: 700px;
}
</style>
