<template>
  <v-btn elevation="0"  @click="callQnbPaymentApi" block height="200" :loading="loader">
    <img src="https://upload.wikimedia.org/wikipedia/commons/8/83/Qatar_National_Bank_Logo.svg" width="150">
  </v-btn>
</template>

<script>
  export default {
    name: "qnb",
    props: {
      value: {
        type: Number,
        default: ()=> {
          return 0
        }
      },
      saveids: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      courtId: {
        type: Number,
        default: ()=>{
          return null
        }
      },
      userId: {
        type: String,
        default: ()=>{
          return ''
        }
      },
      userToken: {
        type: String,
        default: ()=>{
          return ''
        }
      },
      webView: {
        type: Boolean,
        default: ()=>{
          return false
        }
      },
      resultIndicator: {
        type: String,
        default: () => {
          return ''
        }
      }
    },
    data(){
      return {
        apiResponse: {},
        order_id: localStorage.order_id,
        folderUrl: `${process.env.VUE_APP_WEBSITE_URL}wp-content/themes/whistle-child/vue-app`,
        currentUrl: (this.webView)? 'https://payments.khamaylclub.com/' : `${process.env.VUE_APP_WEBSITE_URL}/` ,
        userID: '',
        resultIndicatorWebView: '',
        loader: false
      }
    },
    mounted() {
      this.userID = this.$store.state.user.userId;
      let queryParams =  new URLSearchParams(window.location.search)
      if(this.webView) {
        this.resultIndicator = this.resultIndicatorWebView;
      } else {
        this.resultIndicator = queryParams.get('resultIndicator');
      }
      if (this.resultIndicator) {
        this.checkQnbPayment()
      }
      this.checkoutQnbScript();
    },
    methods: {
      checkoutQnbScript () {
        let checkoutQnbScript = document.createElement('script')
        checkoutQnbScript.setAttribute('src', 'https://qnbalahli.test.gateway.mastercard.com/checkout/version/57/checkout.js')
        checkoutQnbScript.setAttribute('data-complete', this.currentUrl)
        document.head.appendChild(checkoutQnbScript)
      },
      lightBoxPayment(){
        Checkout.configure({
          session: {
            id: this.apiResponse.response.session.id
          },
          merchant: 'TESTQNBAATEST001',
          order: {
            amount: this.value,
            currency: 'EGP',
            description: 'Ordered goods',
            id: this.apiResponse.order_id
          },
          interaction: {
            operation: 'PURCHASE', // set this field to 'PURCHASE' for <<checkout>> to perform a Pay Operation.
            merchant: {
              name: 'Sample Merchant',
              address: {
                line1: '200 Sample St',
                line2: '1234 Example Town'
              }
            }
          }
        });

        Checkout.showLightbox();
      },
      callQnbPaymentApi(){
        this.loader = true
        this.$http_website_without_lang.post('/qnb-payment/',{
          amount:this.value,
          currency:'EGP',
          saveIds: this.saveids,
          userID: this.userID,
          courtId: this.courtId,
        }).then(response => {
          this.apiResponse = response.data;
          this.order_id = response.data.order_id;
          localStorage.order_id = response.data.order_id;
          this.lightBoxPayment();
        }).catch(err => {
          console.log('--->'+err);
        })
      },
      checkQnbPayment(){
        this.$http_website_without_lang.post('/check-payment', {
          order_id: localStorage.order_id,
          result_indicator: this.resultIndicator,
          user_token: this.$store.state.user.userToken
        }).
        then(response => {
          this.$emit('reloadDues')
          // window.close();
        }).catch(err => {
          console.log('error--->'+err);
        })
      }
    },
  }
</script>

<style scoped>

</style>
