<template>
  <div>
    <div>
      <v-row>
<!--        <v-col cols="4">-->
<!--          <v-select-->
<!--            outlined-->
<!--            v-if="type === 'family'"-->
<!--            :items="users"-->
<!--            width="20px"-->
<!--            id="family-select"-->
<!--            attach-->
<!--            auto-->
<!--          >-->
<!--          </v-select>-->
<!--        </v-col>-->

      </v-row>

    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="calories"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:top>


          <v-dialog
            v-model="dialog"
            max-width="500px"
          >

            <change-dialog :edited-item="editedItem" :edited-index="editedIndex" />
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="headline">{{$t('Delete Schedule')}}</v-card-title>
              <v-card-text>
                <p>{{$t('Are sure you want to delete this schedule?')}}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogDelete = false">{{$t('Cancel')}}</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">{{$t('Ok')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </template>
      <template v-slot:item.eDate="{ item }">
        {{moment(item.eDate).format('MM/DD/YYYY')}}
      </template>
      <template v-slot:item.fromTime="{ item }">
        {{common.convert24to12(item.fromTime.hours , item.fromTime.minutes)}}
      </template>
      <template v-slot:item.toTime="{ item }">
        {{common.convert24to12(item.toTime.hours , item.toTime.minutes)}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        Loading
      </template>
    </v-data-table>
  </div>
</template>

<script>
import common from "../../../../utils/common";
import moment from "moment"
import changeDialog from "./changeDialog";
  export default {
    name: "scheduleTable",
    components: {
      changeDialog
    },
    props: {
      type: {
        type: String,
        default: ()=> {
          return 'user'
        }
      },
      headers: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      items: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      users: {
        type: Array,
        default: ()=>{
          return []
        }
      }
    },
    data () {
      return {
        common,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        moment
      }
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = {...item}
        this.editMode = 'edit'
        this.dialog = true
      },
      addItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = {...item}
        this.editMode = 'add'
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = item
        this.dialogDelete = true
      },
      deleteItemConfirm(){
        // this.$http_sys.post('/Schedule/GetScheduleFamily', {
        //   lang: this.$i18n.locale,
        //   groupMemberID: 0
        // })
        //   .then(res => {
        //     this.items = []
        //     for (let i=0;i<res.data.length;i++){
        //       this.users.push(res.data[i].fullName)
        //       this.items.push(res.data[i])
        //     }
        //   })
        //   .catch(error => {
        //
        //   })
      }
    },
    computed: {

    },
    watch: {

    },
  }
</script>

<style scoped>

</style>
