<template>
    <div>
        <v-btn elevation="0"  @click="dialog = true" class="payment-btn" :loading="loading">
            <img src="https://www.logolynx.com//images/logolynx/88/88e4512582365d92692e761fd30ad1cb.jpeg" />
        </v-btn>
        <v-dialog v-model="dialog" width="600">
            <v-card>
                <v-card-title>{{$t('Vodafone Cash')}}</v-card-title>
                <v-card-text>
                    <v-form ref="vodafoneForm">
                        <v-text-field
                          v-model="phone"
                          outlined
                          :placeholder="$t('Enter Vodafone cash phone number')"
                          :rules="[v=> /^010[0-9]{8}$/.test(v) || $t('invalid phone number')]"
                        />
                        <div class="text-center mt-5">
                            <v-btn :loading="loading" :disabled="!phone" color="primary" @click="initPayment">{{$t('Pay')}}</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions class="mt-2">

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    export default {
        name: "vodafone",
        props: {
            value: {
                type: Number,
                default: ()=> {
                    return 0
                }
            },
            service_name: {
                type: String,
                default: ()=> {
                    return ''
                }
            },
            saveids: {
                type: Array,
                default: ()=> {
                    return []
                }
            },
            courtId: {
                type: Number,
                default: ()=>{
                    return null
                }
            },
            subscribeObject: {
                type: Object,
                default: ()=> {
                    return {}
                }
            }
        },
        data() {
            return {
                loading: false,
                phone: '',
                dialog: false
            }
        },
        methods: {
            initPayment() {
                if (this.$refs.vodafoneForm.validate()) {
                    this.loading = true
                    this.$http_website_without_lang.post('paymob-init', {
                        user_token: this.$store.state.user.userToken,
                        user_id: this.$store.state.user.userId,
                        saveids: this.saveids,
                        subscribeObject: this.subscribeObject,
                        amount: this.value,
                        phone: this.phone,
                        courtId: this.courtId,
                        redirection_url: `${process.env.VUE_APP_WEBSITE_URL}user/#${this.$route.path}`
                    })
                      .then(res => {
                          this.loading = false
                          window.location.href = res.data.redirect_url
                      })
                      .catch(err => {
                          this.loading = false
                      })
                }
            }
        }
    }
</script>

<style scoped>

</style>
