<template>
    <div>
        <!-- <cib class="w-100" ref="cib" v-if="order_id && ('onlineApplication' == order_data.type || ('dues' == order_data.type && 'user' == dues_type))" :order_id="order_id" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" /> -->
        <fawry class="w-100 mt-25" ref="fawry" v-if="view_payment" :order_id="order_id" :dues_type="dues_type" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" :isRenewal="isRenewal" />
        <!-- <vodafone class="w-100 mt-25" ref="vodafone" v-if="order_id && vodafone_number && ('onlineApplication' == order_data.type || ('dues' == order_data.type && 'user' == dues_type))" :order_id="order_id" :vodafone_number="vodafone_number" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" /> -->
        
        <!-- show_upon_payment_type_presented -->
        <!-- <cib v-show="!payment_type_presented" class="w-100" ref="cib" v-if="order_id && ('onlineApplication' == order_data.type || ('dues' == order_data.type && 'user' == dues_type))" :order_id="order_id" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" /> -->
        <!-- <fawry v-show="!payment_type_presented" class="w-100 mt-25" ref="fawry" v-if="order_id" :order_id="order_id" :dues_type="dues_type" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" /> -->
        <!-- <vodafone v-show="!payment_type_presented" class="w-100 mt-25" ref="vodafone" v-if="order_id && vodafone_number && ('onlineApplication' == order_data.type || ('dues' == order_data.type && 'user' == dues_type))" :order_id="order_id" :vodafone_number="vodafone_number" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" /> -->
        <!-- show_upon_payment_type_presented -->
        
        <!-- old -->
        <!-- <fawry v-show="order_data" class="d-block mt-25" :value="order_data.value" :saveids="order_data.ids"/> -->
        <!-- <qnb class="d-block" :user-id="userId" :user-token="userToken" :value="totalValue" :saveids="paymentIds" :courtId="courtId" :webView="true" :resultIndicatorWebView="resultIndicator" /> -->
        <!-- old -->
    </div>
</template>

<script>
    import cib from "./payment/cib";
    import fawry from "./payment/fawry";
    import qnb from "./payment/qnb";
    import vodafone from "./payment/vodafone";
    export default {
        name: "payment-options",
        components: {
            cib,
            fawry,
            vodafone,
        },
        data() {
            return {
                order_data: '',
                paymentData: '',
                order_id: '',
                vodafone_number: '',
                dues_type: '',
                payment_type: '',
                totalValue: 0,
                paymentIds: [],
                paymentNames: [],
                userToken: '',
                userId: '',
                courtId: 0,
                isRenewal: false,
                resultIndicator: null,
                payment_type_presented: false,
            }
        },
        computed: {
            view_payment:function(){
                return this.isRenewal||this.order_id;
            },
        },
        beforeMount() {
            this.paymentData = JSON.parse(document.getElementById('user-pages').getAttribute('payment-data'))
            this.order_data = JSON.parse(document.getElementById('user-pages').getAttribute('order_data'))
            this.$i18n.locale = this.paymentData.lang
        },
        mounted() {
            this.paymentData = JSON.parse(document.getElementById('user-pages').getAttribute('payment-data'))
            this.order_data = JSON.parse(document.getElementById('user-pages').getAttribute('order_data'))
            
            if(this.paymentData.dues) {
                this.paymentIds = this.paymentData.dues.map((item)=>{
                return item.id
                })

                this.paymentNames = this.paymentData.dues.map((item)=>{
                    return item.name
                }).join(' - ')
            }

            if(this.paymentData.resultIndicator) {
                this.resultIndicator = this.paymentData.resultIndicator;
                localStorage.setItem('resultIndicator',this.paymentData.resultIndicator)
            }

            this.totalValue = parseFloat(this.paymentData.totalValue);
            this.userToken = this.paymentData.userToken;
            this.userId = this.paymentData.userId;
            this.courtId = this.paymentData.courtId;
            this.isRenewal = this.paymentData.isRenewal;
            
            if (this.order_data) {
                this.totalValue = parseFloat(this.order_data.value);
                this.order_id = this.order_data.order_id;
                this.dues_type = this.order_data.dues_type;
                this.payment_type = this.order_data.payment_type;
                if (this.order_data.type == "dues") {
                    this.paymentIds = this.order_data.ids;
                }
                else if (this.order_data.type == "court") {
                    this.courtId = this.order_data.ids;
                }
                else if (this.order_data.type == "renewal") {
                    this.isRenewal = true;
                }

                localStorage.setItem('totalValue',parseFloat(this.order_data.value))
                localStorage.setItem('order_id',this.order_data.order_id)
                localStorage.setItem('dues_type',this.order_data.dues_type)
                localStorage.setItem('payment_type',this.order_data.payment_type)
                if (this.order_data.type == "dues") {
                    localStorage.setItem('paymentIds',this.order_data.ids)
                }
                else if (this.order_data.type == "court") {
                    localStorage.setItem('courtId',this.order_data.ids)
                }
                else if (this.order_data.type == "renewal") {
                    // localStorage.setItem('isRenewal',true)
                }
                this.payment_type_presented = ["cib", "fawry", "vodafone"].includes(this.order_data.payment_type);
            }
            else{
                localStorage.setItem('totalValue',parseFloat(this.paymentData.totalValue))
                localStorage.setItem('courtId',this.paymentData.courtId)
                // localStorage.setItem('isRenewal',this.paymentData.isRenewal)
            }
            localStorage.setItem('duesNames', this.paymentNames)
            localStorage.setItem('userId', this.paymentData.userId)
            localStorage.setItem('userToken', this.paymentData.userToken)
            localStorage.setItem('userName',this.paymentData.userName)
            localStorage.setItem('membershipId', this.paymentData.membershipId)
            
            const fawry_interval = setInterval(() => {
                if (this.$refs.fawry) {
                    if (this.order_data && this.order_data.payment_type == "fawry") {
                        this.$refs.fawry.FawryPayment();
                    }
                    clearInterval(fawry_interval)
                }
            }, 50);
            const cib_interval = setInterval(() => {
                if (this.$refs.cib) {
                    if (this.order_data && this.order_data.payment_type == "cib") {
                        this.$refs.cib.callPaymentApi();
                    }
                    clearInterval(cib_interval)
                }
            }, 50);
        },
        methods:{
            close() {
                alert('close')
            },
        }
    }
</script>

<style>

</style>
