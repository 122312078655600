<template>
  <div class="favorite-acivity-page">
    <v-container>
      <h3 class=" text-uppercase">{{$t('Join to your favorite sporiting activity')}}</h3>
      <v-form ref="FavoriteAcitvityForm" class="pt-5">
        <v-select
          class="mt-5"
          :items="sports"
          item-text="title"
          item-value="ID"
          v-model="formData.selectSport"
          :rules="[v=> !!v || $t('Sport is required')]"
          outlined
          id="favorite-sport"
          attach
          auto
        >
        <template v-slot:label>
            {{$t('Select Sport')}} <span class="text-red">*</span>
          </template>
        </v-select>
        <v-text-field
          outlined
          v-model="formData.firstName"
          :rules="[v=> !!v || $t('First name is required')]"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('First Name')}} <span class="text-red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.middleName"
          :rules="[v=> !!v || $t('Middle Name is required')]"
          class="mt-5"
          >
          <template v-slot:label>
            {{$t('Middle Name')}} <span class="text-red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.lastName"
          :rules="[v=> !!v || $t('Last Name is required')]"
          class="mt-5"
          >
          <template v-slot:label>
            {{$t('Last Name')}} <span class="text-red">*</span>
          </template>
        </v-text-field>
        <div class="relative">
          <v-menu
            ref="dobMenu"
            v-model="dobMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            class="max-500"
            attach
            auto
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-5"
                v-model="formData.birthDate"
                :rules="[v=> !!v || $t('Date Of Birth is required')]"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:label>
                  {{$t('Date Of Birth')}} <span class="text-red">*</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="formData.birthDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="saveDob"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-radio-group v-model="formData.gendar" row class="mt-5">
          <template>
            <label class="radio-label">{{$t('Gendar')}} <span class="text-red">*</span></label>
          </template>
          <v-radio :label="$t('Male')" value="male" aria-selected="true"></v-radio>
          <v-radio :label="$t('Female')" value="female"></v-radio>
        </v-radio-group>
        <v-select
            class="mt-5"
            :items="nationalities"
            v-model="formData.nationality"
            :rules="[v=> !!v || $t('Nationality is required')]"
            outlined
            id="your-nationality"
            attach
            auto
          >
          <template v-slot:label>
            {{$t('Nationality')}} <span class="text-red">*</span>
          </template>
        </v-select>
        <v-text-field
          outlined
          v-model="formData.landline1"
          :rules="[v=> !!v || $t('Landline no# 1  is required')]"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('Landline no# 1')}} <span class="text-red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.landline2"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('Landline no# 2')}}
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.mobile1"
          :rules="[v=> !!v || $t('Mobile 1  is required')]"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('Mobile 1')}} <span class="text-red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.mobile2"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('Mobile 2')}}
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.email"
          class="mt-5"
          :rules="[rules.email]"
        >
          <template v-slot:label>
            {{$t('Email')}} <span class="text-red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.activityYears"
          class="mt-5"
          type="number"
        >
          <template v-slot:label>
            {{$t('No# of years playing activity')}}
          </template>
        </v-text-field>
         <v-text-field
          outlined
          v-model="formData.no_tournament"
          class="mt-5"
          type="number"
        >
          <template v-slot:label>
            {{$t('No# of tournament played')}}
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.nationalRank"
          class="mt-5"
          type="number"
        >
          <template v-slot:label>
            {{$t('National rank')}}
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.clubName"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('Club name')}}
          </template>
        </v-text-field>
        <v-text-field
          outlined
          v-model="formData.lastLevel"
          class="mt-5"
        >
          <template v-slot:label>
            {{$t('Last level played in')}}
          </template>
        </v-text-field>
      </v-form>
      <v-card-actions class="d-block">
        <v-btn :loading="loader"  width="150" @click="sendMail" elevation="0" color="primary">{{$t('Send Mail')}}</v-btn>
      </v-card-actions>
      <div class="mt-5 success-msg" v-if="sendsuccess == 1">
        <p>{{ $t('Successfully sent, we will contact you as soon as possible')}}</p>
      </div>
      <div class="mt-5 failed-msg" v-if="sendsuccess == 2">
        <p>{{ $t('Submission failed, please try again')}}</p>
      </div>
    </v-container>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        dobMenu: false,
        sendsuccess: 0,
        rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        formData: {
          selectSport: '',
          firstName: '',
          middleName: '',
          lastName: '',
          birthDate: '',
          gendar: 'male',
          nationality: '',
          landline1: '',
          landline2: '',
          mobile1: '',
          mobile2: '',
          email: '',
          activityYears: '',
          no_tournament: '',
          nationalRank: '',
          clubName: '',
          lastLevel: '',
        },
        sports: [],
        nationalities: [
          "Afghanistan",
          "Albania",
          "Algeria",
          "American Samoa",
          "Andorra",
          "Angola",
          "Anguilla",
          "Antarctica",
          "Antigua And Barbuda",
          "Argentina",
          "Armenia",
          "Aruba",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bermuda",
          "Bhutan",
          "Bolivia",
          "Bosnia And Herzegowina",
          "Botswana",
          "Bouvet Island",
          "Brazil",
          "British Indian Ocean Territory",
          "Brunei Darussalam",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Cape Verde",
          "Cayman Islands",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Christmas Island",
          "Cocos (Keeling) Islands",
          "Colombia",
          "Comoros",
          "Congo",
          "Congo, The Democratic Republic Of The Cook Islands",
          "Costa Rica",
          "Cote D'Ivoire",
          "Croatia (Local Name: Hrvatska)",
          "Cuba",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "Timor-Leste (East Timor)",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Falkland Islands (Malvinas)",
          "Faroe Islands",
          "Fiji",
          "Finland",
          "France",
          "France, Metropolitan",
          "French Guiana",
          "French Polynesia",
          "French Southern Territories",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Grenada",
          "Guadeloupe",
          "Guam",
          "Guatemala",
          "Guinea",
          "Guinea-Bissau",
          "Guyana",
          "Haiti",
          "Heard And Mc Donald Islands",
          "Holy See (Vatican City State)",
          "Honduras",
          "Hong Kong",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Iran (Islamic Republic Of)",
          "Iraq",
          "Ireland",
          "Italy",
          "Jamaica",
          "Japan",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Korea, Democratic People's Republic Of",
          "Korea, Republic Of",
          "Kuwait",
          "Kyrgyzstan",
          "Lao People's Democratic Republic",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libyan Arab Jamahiriya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macau",
          "Macedonia, Former Yugoslav Republic Of",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Martinique",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Mexico",
          "Micronesia, Federated States Of",
          "Moldova, Republic Of",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Montserrat",
          "Morocco",
          "Mozambique",
          "Myanmar",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "Netherlands Antilles",
          "New Caledonia",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "Niue",
          "Norfolk Island",
          "Northern Mariana Islands",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Pitcairn",
          "Poland",
          "Portugal",
          "Puerto Rico",
          "Qatar",
          "Reunion",
          "Romania",
          "Russian Federation",
          "Rwanda",
          "Saint Kitts And Nevis",
          "Saint Lucia",
          "Saint Vincent And The Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome And Principe",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Slovakia (Slovak Republic)",
          "Slovenia",
          "Solomon Islands",
          "Somalia",
          "South Africa",
          "South Georgia, South Sandwich Islands",
          "South Sudan",
          "Spain",
          "Sri Lanka",
          "St. Helena",
          "St. Pierre And Miquelon",
          "Sudan",
          "Suriname",
          "Svalbard And Jan Mayen Islands",
          "Swaziland",
          "Sweden",
          "Switzerland",
          "Syrian Arab Republic",
          "Taiwan",
          "Tajikistan",
          "Tanzania, United Republic Of Thailand",
          "Togo",
          "Tokelau",
          "Tonga",
          "Trinidad And Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Turks And Caicos Islands",
          "Tuvalu",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States",
          "United States Minor Outlying Islands",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Venezuela",
          "Viet Nam",
          "Virgin Islands (British)",
          "Virgin Islands (U.S.)",
          "Wallis And Futuna Islands",
          "Western Sahara",
          "Yemen",
          "Yugoslavia",
          "Zambia",
          "Zimbabwe"
        ],
        loader: false
      }
    },
     mounted() {
     this.getSports()
    },
    methods: {
      saveDob (date) {
        this.$refs.dobMenu.save(date)
      },
      sendMail () {
        if (this.$refs.FavoriteAcitvityForm.validate()) {
          this.loader = true
          this.$http_sys_no_auth.post('https://cityclubegypt.com/wp-json/api/v1/en/send-email',{
            "subject": "CityClub : Join To Favorite Sporting Activity",
            "formSlug": "favorite-sporting-activity",
            "data" : this.formData
            })
            .then(response=>{
              console.log(response)
              if(response.data == 'Success') {
                this.sendsuccess = 1;
              }else{
                this.sendsuccess = 2;
              }
              this.loader = false
            })
            .catch(error=>{
              this.loader = false
              this.sendsuccess = 2;
              console.log(error)
            })
        }
      },
      getSports () {
        this.$http_website.get('sports')
          .then(response=>{
            this.sports = response.data
          })
          .catch(error=>{
            console.log(error)
          })
      },
    },
    watch: {
      dobMenu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    }
  }
</script>
