<template>
  <div class="favorite-acivity-page">
    <v-container v-if="active">
      <h3 class="text-uppercase">{{ $t("Subscribe") }}</h3>
      <div class="row">
        <div class="col-12 col-md-6">
          <v-form ref="SubscribeForm"  class="pt-5">
            <div class="row">
              <div class="col-md-6 col-6">
                <v-text-field
                  outlined
                  v-model="formData.firstName"
                  :rules="[(v) => !!v || $t('First name is required')]"
                  class="mt-5"
                >
                  <template v-slot:label>
                    {{ $t("First Name") }} <span class="text-red">*</span>
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-6 col-6">
                <v-text-field
                  outlined
                  v-model="formData.secondName"
                  :rules="[(v) => !!v || $t('Second name is required')]"
                  class="mt-5"
                >
                  <template v-slot:label>
                    {{ $t("Second Name") }} <span class="text-red">*</span>
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-6 col-6">
                <v-text-field
                  outlined
                  v-model="formData.thirdName"
                  :rules="[(v) => !!v || $t('Third name is required')]"
                  class="mt-5"
                >
                  <template v-slot:label>
                    {{ $t("Third Name") }} <span class="text-red">*</span>
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-6 col-6">
                <v-text-field
                  outlined
                  v-model="formData.fourthName"
                  :rules="[(v) => !!v || $t('Fourth name is required')]"
                  class="mt-5"
                >
                  <template v-slot:label>
                    {{ $t("Fourth Name") }} <span class="text-red">*</span>
                  </template>
                </v-text-field>
              </div>
            </div>
            <v-radio-group v-model="formData.sexTypeID" row class="mt-5">
              <template>
                <label class="radio-label">{{$t('Gender')}} <span class="text-red">*</span></label>
              </template>
              <v-radio :label="$t('Male')" :value="1" aria-selected="true"></v-radio>
              <v-radio :label="$t('Female')" :value="0"></v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              v-model="formData.mobile1"
              :rules="[
                  (v) => !!v || $t('Mobile Phone is required with english numbers'),
                  v => v.length === 11 || $t('Invalid Mobile Number'),
                  v=> !/^[A-Za-z][A-Za-z0-9]*$/.test(v) || $t('Only english numbers allowed')
                  ]"
              type="number"
              class="mt-5"
            >
              <template v-slot:label>
                {{ $t("Mobile Phone") }} <span class="text-red">*</span>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              v-model="formData.email"
              class="mt-5"
              :rules="[rules.email]"
            >
              <template v-slot:label>
                {{ $t("Email") }} <span class="text-red">*</span>
              </template>
            </v-text-field>
            <div class="relative">
              <v-menu
                ref="dobMenu"
                v-model="dobMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                class="max-500"
                attach
                auto
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mt-5"
                    v-model="formData.birthDate"
                    :rules="[(v) => !!v || $t('Date Of Birth is required')]"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      {{ $t("Date Of Birth") }} <span class="text-red">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="formData.birthDate"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="saveDob"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <v-text-field
              outlined
              v-model="formData.nationalId"
              type="number"
              :rules="[(v) => !!v || $t('National ID  is required with english numbers') , v => v.length === 14 || $t('Invalid National ID')]"
              class="mt-5"
            >
              <template v-slot:label>
                {{ $t("National ID") }} <span class="text-red">*</span>
              </template>
            </v-text-field>
            <v-select class="mt-5" :items="branchesList" item-text="name" item-value="branchId" v-model="formData.branchId"
                      :rules="[v=> v !=='' || $t('Branch is required')]" outlined id="branch" attach auto>
              <template v-slot:label>
                {{$t('Select Branch')}} <span class="text-red">*</span>
              </template>
            </v-select>
            <v-select class="mt-5" :items="qualifications" item-text="name" item-value="id" v-model="formData.educationLevelId"
                      :rules="[v=> !!v || $t('Qualification is required')]" outlined id="qalification" attach auto>
              <template v-slot:label>
                {{$t('Select Qualification')}} <span class="text-red">*</span>
              </template>
            </v-select>
            <v-select class="mt-5" :items="materialStatusList" item-text="name" item-value="id" v-model="formData.maritalstatusId"
                      :rules="[v=> !!v || $t('Social status is required')]" outlined id="material-status" attach auto>
              <template v-slot:label>
                {{$t('Social status')}} <span class="text-red">*</span>
              </template>
            </v-select>

            <v-radio-group v-model="formData.isCash" row class="mt-5">
              <template>
                <label class="radio-label">{{$t('Payment Method')}} <span class="text-red">*</span></label>
              </template>
              <v-radio :label="$t('Cash')" :value="1" aria-selected="true"></v-radio>
              <v-radio :label="$t('Installment')" :value="0"></v-radio>
            </v-radio-group>
            <v-select v-if="formData.isCash === 0" class="mt-5" :items="months" item-text="name" item-value="id" v-model="formData.installmentsNumber"
                      :rules="[v=> !!v || $t('Months is required')]" outlined id="branch" attach auto>
              <template v-slot:label>
                {{$t('Select Months')}} <span class="text-red">*</span>
              </template>
            </v-select>
            <label
            ><input
              type="checkbox"
              name="terms"
              value="1"
              v-model="agreement"
              aria-invalid="false"
            /><span class="wpcf7-list-item-label"
            >موافق علي
            <a href="http://cityclubeg.com/%d8%b4%d8%b1%d9%88%d8%b7-%d8%a7%d9%84%d8%a7%d8%b3%d8%aa%d8%ae%d8%af%d8%a7%d9%85-%d9%85%d9%88%d8%a8%d9%8a%d9%84/">
              الشروط و الاحكام</a
            ></span
            ></label
            >
            <v-card-actions class="d-block">
              <v-btn :loading="loader" @click="send" :disabled="!agreement" class="mt-3" width="150" elevation="0" color="primary">{{$t('Pay')}}</v-btn>
            </v-card-actions>
            <v-dialog v-model="dialog" width="700" eager >
              <v-card class="pa-5 payment-logos">
                <v-layout row wrap>
                  <v-flex md3 class="mt-5 d-flex justify-center">
                    <cib  :value="fees" :subscribe-object="formData" :service_name="['Subscribe Form']"/>
                  </v-flex>
                  <v-flex md3 class="mt-5 d-flex justify-center">
                    <fawry  :value="fees" :subscribe-object="formData"  />
                  </v-flex>
                  <v-flex md3 class="mt-5 d-flex justify-center">
                    <vodafone  :value="fees" :subscribe-object="formData"  />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-dialog>
          </v-form>
        </div>
        <div class="col-12 col-md-6 hidden-sm-and-down">
          <img src="https://cityclubeg.com/wp-content/uploads/2021/04/WEBSITE-1.png" alt="">
        </div>
      </div>
    </v-container>
    <v-container v-else>
      <h3>تم غلق المرحلة الثالثة</h3>
    </v-container>
  </div>
</template>
<script>
  import cib from "../../payment/cib";
  import vodafone from "../../payment/vodafone";
  import fawry from "../../payment/fawry";
export default {
  components: {
    cib,
    vodafone,
    fawry
  },
  mounted() {
    this.getBranches()
  },
  data() {
    return {
      active: true,
      rules: {
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('Invalid e-mail');
        },
      },
      fees: 3000,
      dialog: false,
      dobMenu: false,
      agreement: false,
      formData: {
        firstName: "",
        secondName: "",
        thirdName: "",
        fourthName: "",
        birthDate: "",
        sexTypeID: 1,
        maritalstatusId : "",
        mobile1: "",
        email: "",
        branchId: "",
        isCash: 1,
        installmentsNumber: "",
        nationalId: "",
        educationLevelId : ""
      },
      qualifications: [
        {
          id: 1,
          name: 'مؤهل عالي'
        },
        {
          id: 2,
          name: 'مؤهل متوسط'
        },
        {
          id: 3,
          name:'بدون مؤهل'
        },
      ],
      materialStatusList: [
        {
          id: 1,
          name: 'أعزب'
        },
        {
          id: 2,
          name: 'متزوج'
        },
        {
          id: 3,
          name: 'أرمل'
        },
        {
          id: 4,
          name: 'مطلق'
        }
      ],
      months :[
        {
          id: 12,
          name: '١٢ شهور'
        },
        {
          id: 24,
          name: '٢٤ شهور'
        },
        {
          id: 36,
          name: '36 شهور'
        },
      ],
      branchesList: [],
      loader: false,
    };
  },
  methods: {
    saveDob(date) {
      this.$refs.dobMenu.save(date);
    },
    getBranches() {
      this.$http_sys_no_auth.post('Authentication/GetBranches',{
        lang: this.$i18n.locale
      })
        .then(res=>{
          this.branchesList = res.data
        })
        .catch(error=>{

        })
    },
    send() {
      if (this.$refs.SubscribeForm.validate()) {
        this.dialog = true
      }
    }
  },
  watch: {
    dobMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>
<style scoped>
[dir=rtl] .radio-label {
  margin-right: 0 !important;
}
  img {
    height: 800px;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }
</style>
