<template>
        <div>
            <slot />
          <page-loader></page-loader>
          <snackbar-message></snackbar-message>
        </div>

</template>

<script>
  import pageLoader from '../components/common/loader'
  import snackbarMessage from '../components/common/snackBar'

    export default {
        name: "NoSidebar",
      components: {

        pageLoader,
        snackbarMessage,

      },

    }
</script>

<style scoped>

</style>
